import React, { useEffect, useState, useRef, useMemo } from "react";
import MetaTags from "react-meta-tags";
import toastr from "toastr";
import Badge from 'react-bootstrap/Badge';
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "react-multiple-select-dropdown-lite/dist/index.css";
import "toastr/build/toastr.min.css";
import Switch from "react-switch"
import MultiSelect from "react-multiple-select-dropdown-lite";
import _ from 'lodash'
import { Button, Card, CardBody, Col, Container, Row, Label, Input, Modal, ModalHeader, ModalBody, Form, FormGroup } from "reactstrap";
import { AvForm, AvField, Lable } from "availity-reactstrap-validation";

import { useSelector, useDispatch } from "react-redux";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import Pages404 from "pages/Utility/pages-404";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment";
import DataTable from "react-data-table-component";
import { searchUsers, getPromosCode, getLaundries, getArealist, createPromoCode, updatePromoCode, getRecord, getTransactions, getClothesList } from "store/actions";
import ReactSwitch from "react-switch"
import Select from "react-select"
import { Offsymbol, OnSymbol } from "components/Common/Symbol"
import { postArea } from "helpers/LaundryHelper"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const Nosymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      No
    </div>
  )
}

const YesSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Yes
    </div>
  )
}

const PromoCode = props => {
  const dispatch = useDispatch();
  var node = useRef();
  const { promoCodes, totalCount, users, laundryList, areaList, promocodeError, Allorders, transactions, clothes } = useSelector(state => ({
    Allorders: state.Records.records,
    users: state.Users.users,
    promoCodes: state.Promos.promoCodes,
    totalCount: state.Promos.totalCount,
    transactions: state.Promos.transactions,
    promocodeError: state.Promos.error,
    areaList: state.ecommerce.arealist,
    laundryList: state.Laundries.laundryList,
    clothes: state.Clothes.clothesList,
  }));
  const [checkstatus, setcheckstatus] = useState(false)
  const [addModal, setAddModal] = useState(false);
  const [promoCodeList, setPromoCodeList] = useState([]);
  const [userData, setUserData] = useState({});
  const [category, setCategory] = useState("online");
  const [promoCodeArea, setPromoCodeArea] = useState([]);
  const [promoCodeClothes, setPromoCodeClothes] = useState([]);
  const [promoCodeLaundry, setPromoCodeLaundry] = useState([]);
  const [laundryName, setlaundryName] = useState({ value: "", label: "" });
  const [codePercentage, setCodePercentage] = useState(0);
  const [amount, setAmount] = useState(0);
  const [discountType, setDiscountType] = useState("discount");
  const [promoCode, setPromoCode] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState(true);
  const [newCustomer, setNewCustomer] = useState(true);
  const [enabledForAll, setEnabledForAll] = useState(false);
  const [count, setCount] = useState(0);
  const [maxDiscount, setMaxDiscount] = useState();
  const [minOrder, setMinOrder] = useState();
  const [error, setError] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [perItemDiscount, setperItemDiscount] = useState(false)
  const [autoApplyOnCreate, setautoApplyOnCreate] = useState(false)
  const [rows, setRows] = useState([{ item: '', discountedPrice: 0, perItemType: "" , maxItemsApplicable: 0, itemName: '' }]);
  const [isEdit, setIsEdit] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [showUsage, setShowUsage] = useState(false);
  const [apiProcessing, setApiProcessing] = useState(false);
  const [usageList, setUsageList] = useState([]);
  const [transactionList, setTransactionList] = useState([]);
  const [extended, setExtended] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const serviceApplicableDefault = {
    iron: false,
    dryClean: false,
    washIron: false,
    expressIron: false,
    expressDryClean: false,
    expressWashIron: false
  }
  const [serviceApplicable, setserviceApplicable] = useState(serviceApplicableDefault)
  const [clothesDataList, setClothesDataList] = useState([]);
  const [perItemType, setPerItemType] = useState([{
    value: "sr",
    label: "SR",
    _id: "1",
    discountType: "sr"
  },{
    value: "per",
    label: "%",
    _id: "2",
    discountType: "per"
  },]);

  const pageOptions = {
    sizePerPage: 10,
    totalSize: promoCodeList?.length || 0, // replace later with size(orders),
    custom: true,
  };

  const typesList = [
    { value: "discount", "label": "Discount" },
    { value: "wallet", "label": "Wallet" },
    { value: "areaDiscount", "label": "Area Discount" },
    { value: "areaFreeRechargeWallet", "label": "Area Free Recharge Wallet" },
  ]

  const [fetchStatusTrue, setFetchStatusTrue] = useState(true)
  const [fetchStatusFalse, setFetchStatusFalse] = useState(true)


  const [selectedArea, setSelectedArea] = React.useState(null)
  const [selectedLaundry, setSelectedLaundry] = React.useState(null)
  const [selectedType, setSelectedType] = React.useState(null)
  const [selectCategory, setselectCategory] = React.useState("")
  const [areas, setAreas] = React.useState([])
  const [pending, setPending] = useState(true)

  React.useEffect(async () => {
    const AreaResponse = await postArea({})
    setAreas(
      AreaResponse?.record?.map(item => {
        return { label: item?.name, value: item?._id, ...item }
      })
    )
    dispatch(getClothesList({}))
    if (!users) dispatch(searchUsers({ webPermissions: true }))
    if (!promoCode) dispatch(getPromosCode({ webPermission: true, pageNo: currentPage, pageSize }));
    if (!laundryList.length) dispatch(getLaundries({}))
    if (!areaList.length) dispatch(getArealist({}));
  }, [])

  useEffect(() => {
    setClothesDataList(clothes)
  }, [clothes])
  useEffect(() => {
    setUsageList(Allorders)
    setShowUsage(true);
    setApiProcessing(false)
  }, [Allorders])

  useEffect(() => {
    setTransactionList(transactions)
    setShowUsage(true);
    setApiProcessing(false)
  }, [transactions])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])


  useEffect(() => {
    if (!users) dispatch(searchUsers({ webPermissions: true }))
    if (!promoCode) dispatch(getPromosCode({ webPermission: true, pageNo: currentPage, pageSize }));
    if (!laundryList?.length) dispatch(getLaundries({}))
    if (!areaList?.length) dispatch(getArealist({}));
  }, [])

  // useEffect(() => {
  //   if (newCustomer) {
  //     setCount("")
  //   }
  // }, [newCustomer])


  const isFirstRun = useRef(true);

  useEffect(() => {
    setPromoCodeList(promoCodes);
    setPending(false)
  }, [promoCodes]);

  // Memoize the debounced handleSearch function
  const debouncedHandleSearch = useRef(_.debounce((value) => {
    handleSearch(value);
  }, 1500)).current; // Use .current to access the debounced function directly


  useEffect(() => {
    setPending(true)
    if (!isFirstRun.current) {
      debouncedHandleSearch(searchValue); // Call the debounced handleSearch with searchValue
    } else {
      isFirstRun.current = false;
    }
  }, [searchValue, debouncedHandleSearch]); // Include debouncedHandleSearch in dependencies

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };
  useEffect(() => {
    setPending(true);
    let status;

    if (fetchStatusTrue === false && fetchStatusFalse === false) {
      setPromoCodeList([]);
    } else {
      if (fetchStatusTrue === true && fetchStatusFalse === false) {
        status = true;
      } else if (fetchStatusTrue === false && fetchStatusFalse === true) {
        status = false;
      }
      let filter = { webPermission: true, status, pageNo: 1, pageSize }; // Reset page number to 1

      if (selectedType && !selectCategory) {
        const category = selectedArea?.value ? "online" : "offline";
        filter = {
          ...filter,
          category,
          laundry: category === "offline" ? selectedLaundry?.value : null,
          type: selectedType?.value,
          area: selectedArea?.value,
        };
      } else if (selectCategory?.value === "online" || selectCategory?.value === "offline") {
        filter = {
          ...filter,
          category: selectCategory.value,
          ...(selectCategory.value === "offline" && { laundry: selectedLaundry?.value }),
          ...(selectedType?.value && { type: selectedType.value }),
          ...(selectedArea?.value && { area: selectedArea.value }),
        };
      } else if (!selectCategory && !selectedType && selectedArea) {
        filter = {
          ...filter,
          category: "online",
          area: selectedArea.value,
          ...(selectedType?.value && { type: selectedType.value }),
        };
      }

      dispatch(getPromosCode(filter));

    }

  }, [currentPage, pageSize, selectCategory, selectedLaundry, selectedArea, fetchStatusFalse, fetchStatusTrue, selectedType]);
  const handleSearch = (val) => {
    setSearchValue(val?.toLowerCase())
    if (!val) {
      dispatch(getPromosCode({ webPermission: true, pageNo: currentPage, pageSize }))
    } else {
      dispatch(getPromosCode({ webPermission: true, code: val.toLowerCase(), pageNo: currentPage, pageSize }))
    }
    setPending(false)
    // val = val?.toLowerCase();
    // if (!val) {
    //   setPromoCodeList(promoCodes)
    // } else {
    //   let filterBySearch = promoCodes.filter((item) => item?.code?.toLowerCase()?.includes(val) || item?.area?.findIndex((data) => data?.name?.toLowerCase()?.includes(val)) !== -1 || item?.type?.toLowerCase()?.includes(val));
    //   setPromoCodeList(filterBySearch)
    // }
  }

  const handleEditPromoCode = (row) => {
    setlaundryName({ value: row.laundry[0]?._id, label: row.laundry[0]?.shortName ? row.laundry[0]?.shortName : row.laundry[0]?.nameEn })
    setCategory(row?.category ? row?.category : "online");
    setPromoCodeArea(row?.area?.map((item) => item._id));
    setPromoCodeClothes(row?.excluded_items?.map((item) => item._id));
    setPromoCodeLaundry(row?.laundry?.map((item) => item._id));
    setCodePercentage(row?.percent ? row?.percent : 0);
    setAmount(row?.amount ? row?.amount : 0);
    setDiscountType(row?.type ? row?.type : "discount");
    setPromoCode(row?.code ? row?.code : "");
    setStartDate(row?.start ? row?.start : "");
    setEndDate(row?.end ? row?.end : "");
    setStatus(row?.status);
    setNewCustomer(row?.onlyNew);
    setEnabledForAll(row?.enableAll || false);
    setCount(row?.count ? row?.count : 0);
    setMaxDiscount(row?.maximum ? row?.maximum : 0);
    setMinOrder(row?.minimumOrder ? row?.minimumOrder : 0);
    setserviceApplicable(row?.services ? row.services : serviceApplicable)
    setExtended(row?.extended);
    setSelectedItem(row);
    setRows(row?.item_discounted)
    setperItemDiscount(row?.perItemDiscount ? row.perItemDiscount : false)
    setIsEdit(true)
    setautoApplyOnCreate(row?.autoApplyOnCreate ? row?.autoApplyOnCreate : false)
    setAddModal(true)
    // for old promocodes with no services object.
    if (row?.services == null) {
      setserviceApplicable({
        iron: true,
        dryClean: true,
        washIron: true,
        expressIron: true,
        expressDryClean: true,
        expressWashIron: true
      })
    }
  }

  const handleCheckboxChange = (type) => setserviceApplicable({ ...serviceApplicable, [type]: !serviceApplicable[type] })
  let promoCodeListColumn = [
    {
      id: "code",
      name: "Code",
      sortable: false,
      wrap: true,
      selector: row => row.code
    }, {
      id: "type",
      name: "Type",
      sortable: false,
      wrap: true,
      selector: row => row?.category ? row?.category + " - " + row?.type : row?.type
    }, {
      id: "discountWallet",
      name: "Discount/Wallet",
      sortable: false,
      wrap: true,
      // center: true,
      selector: row => row?.type == "discount" ? row?.percent + " %" : row?.type == "wallet" ? row?.amount + " SR" : "N/A"
    }, {
      id: "customerType",
      name: "Customer Type",
      sortable: false,
      wrap: true,
      // center: true,
      selector: row => row?.onlyNew ? "New" : "All"
    }, {
      id: "area",
      name: "Area/Laundry",
      sortable: false,
      wrap: true,
      selector: (row) => {
        if (row?.category == "online") {
          return (
            <div className="mt-1">
              {
                row?.enableAll == true ? <h4><Badge bg="info">All Areas</Badge></h4> : row?.area?.map((item2, index) => {
                  return item2?.name + " | "
                })
              }
            </div>

          )
        } else if (row?.category == "offline") {
          return (
            <div>
              {
                row?.enableAll == true ? <h4><Badge bg="info">All Laundries</Badge></h4> : row?.laundry?.map((item2, index) => {
                  const name = item2.shortName
                    ? item2.shortName
                    : item2.nameEn
                  return name + " | "
                })}
            </div>
          )
        }
      },

    }, {
      id: "view",
      name: "View",
      center: true,
      selector: (row) => {
        return (
          <button className="btn btn-primary btn-sm" onClick={() => handleEditPromoCode(row)}>View</button>
        )
      }
    }
  ];

  const addRow = () => {
    setRows([...rows, { item: '', perItemType: "" ,discountedPrice: 0, maxItemsApplicable: 0 }]);
  };

  const deleteRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleChange = (index, key, value) => {
 
    const newRows = [...rows];
    if (key == "item") {
      newRows[index][key] = value.value;
    } else if (key == "perItemType") {
      if(promocodeError['perItemDiscount']){
        delete error['perItemDiscount']
      }
      newRows[index][key] = value.value;
    }
    else {
      newRows[index][key] = value;
    }
    setRows(newRows);
  };

  const defaultSorted = [
    {
      dataField: "orderID",
      order: "desc",
    },
  ];

  const handleAddPromoCode = () => {
    setAddModal(true)
    setlaundryName({ value: "", label: "" })
  }

  const handleAreaChange = (val) => {
    setPromoCodeArea(val ? val.split(",") : [])
  }
  const handleClothChange = (val) => {
    setPromoCodeClothes(val ? val.split(",") : [])
  }

  const handleLaundry = (val) => {
    if (discountType === "areaDiscount" || discountType === "areaFreeRechargeWallet") {
      setlaundryName(val)
    }
    if (discountType === "discount") {
      setPromoCodeLaundry(val ? val.split(",") : [])
    }
  }

  function hasDuplicates(array) {
    const seen = new Set();
    return array.some(obj => {
      // Check if 'item' is duplicated
      if (seen.has(obj.item)) {
        return true;
      }
      seen.add(obj.item);
      return false;
    });
  }
  const addPromoCode = async (event, values) => {
    let promocodeError = {};
    let errorExits = false;
    if (hasDuplicates(rows) && perItemDiscount == true) {
      promocodeError['perItemDiscount'] = "Each item must be Uniuqe and All Fields are required";
      errorExits = true;
    }
    if (promoCodeArea?.length < 1 && category === "online" && !enabledForAll) {
      promocodeError['area'] = "Area Required";
      errorExits = true;
    }
    if (!promoCodeLaundry && category === "offline" && !enabledForAll && discountType === "discount") {
      promocodeError['laundry'] = "Laundry Required";
      errorExits = true;
    }
    if (!laundryName.value && category === "offline" && !enabledForAll && (discountType === "areaFreeRechargeWallet" || discountType === "areaDiscount")) {
      promocodeError['laundry'] = "Laundry Required";
      errorExits = true;
    }
    if (!startDate) {
      promocodeError['startDate'] = "Start Date Required";
      errorExits = true;
    }
    if (!endDate) {
      promocodeError['endDate'] = "End Date Required";
      errorExits = true;
    }
    if (new Date(startDate) > new Date(endDate)) {
      promocodeError['endDate'] = "End Date can't be less then Start Date";
      errorExits = true;
    }
    if (!promoCode || promoCode == "") {
      promocodeError['code'] = "Promocode Required";
      errorExits = true;
    }
    let maxPercentage = (!userData?.permissions?.allPermissions && userData?.permissions?.promoCode?.validations?.maxOnlineDiscount && category === "online") ? parseFloat(userData?.permissions?.promoCode?.validations?.maxOnlineDiscount) : (!userData?.permissions?.allPermissions && userData?.permissions?.promoCode?.validations?.maxOfflineDiscount && category === "offline") ? parseFloat(userData?.permissions?.promoCode?.validations?.maxOfflineDiscount) : 100;
    let pettern = /^[.0-9]*$/

    let maxAreaDiscount = (!userData?.permissions?.allPermissions && userData?.permissions?.promoCode?.validations?.maxAreaDiscount && category === "offline" && discountType == "areaDiscount") ? parseFloat(userData?.permissions?.promoCode?.validations?.maxAreaDiscount) : 100

    let maxAreaFreeRechargeWallet = (!userData?.permissions?.allPermissions && userData?.permissions?.promoCode?.validations?.maxAreaFreeRechargeWalletAmount && category === "offline" && discountType == "areaFreeRechargeWallet") ? parseFloat(userData?.permissions?.promoCode?.validations?.maxAreaFreeRechargeWalletAmount) : 10

    //If perItemType is % then discount value shall be between 1-99, else toast error:
    if (rows.length > 0 && rows[0]?.perItemType == 2) {
      rows.map((row) => {
        if (row?.discountedPrice < 1 || row?.discountedPrice > 99) {
          toastr.error("Enter a Valid Percentage (1-99)");
          promocodeError['perItemDiscount'] = "Enter a Percentage between 1-99";
          errorExits = true;
        }
      })
    }

    if ((codePercentage < 1 || codePercentage > maxPercentage || !pettern.test(codePercentage)) && !isEdit && (discountType === "discount" && perItemDiscount == false)) {
      promocodeError['percentage'] = "Enter a Valid 1-" + maxPercentage + " Percentage";
      errorExits = true;
    } else if (!codePercentage && codePercentage == 0 && ((discountType == "discount" && perItemDiscount == false && isEdit == false) || (discountType == "areaDiscount"))) {
      promocodeError['percentage'] = "Percentage Required";
      errorExits = true;
    } else if (category == "offline" && discountType == "areaDiscount" && (!codePercentage && codePercentage != "0") || (codePercentage > maxAreaDiscount)) {
      promocodeError['percentage'] = `Percentage not allowed more than ${maxAreaDiscount}%`;
      errorExits = true;
    } else if (category == "offline" && isEdit == false && discountType == "areaFreeRechargeWallet" && amount > maxAreaFreeRechargeWallet) {
      promocodeError['amount'] = `Amount not allowed more than ${maxAreaFreeRechargeWallet} SR`;
      errorExits = true;
    } else if (category == "offline" && discountType == "areaFreeRechargeWallet" && (!amount && amount == "0")) {
      promocodeError['amount'] = `Amount Required`;
      errorExits = true;
    }
    let maxAmount = (!userData?.permissions?.allPermissions && userData?.permissions?.promoCode?.validations?.maxWalletAmount) ? parseFloat(userData?.permissions?.promoCode?.validations?.maxWalletAmount) : null
    let petternAmt = /^[0-9]*$/
    if ((!amount || amount < 1 || !petternAmt.test(amount) || (maxAmount && amount > maxAmount)) && !isEdit && (discountType === "wallet" || discountType === "areaFreeRechargeWallet")) {
      promocodeError['amount'] = maxAmount ? "Enter a Valid 0-" + maxAmount + " Amount" : "Enter a Valid Amount";
      errorExits = true;
    }

    if ((maxDiscount < 0 || (maxDiscount === undefined || maxDiscount === null || maxDiscount === "")) && ((discountType === "discount" && perItemDiscount == false) || (discountType === "areaDiscount"))) {
      promocodeError['maxDiscount'] = "Enter a Valid Maximum discount";
      errorExits = true;
    }
    if ((minOrder < 0 || (minOrder === undefined || minOrder === null || minOrder === "")) && category === "offline" && (discountType == "discount" || discountType == "areaDiscount" || discountType == "areaFreeRechargeWallet")) {
      promocodeError['minOrder'] = "Enter Valid Minimum Order";
      errorExits = true;
    }
    if (!newCustomer && (count < 1 || (count === undefined || count === null || count === "")) && category === "online") {
      promocodeError['count'] = "Enter Valid No of usages per Customer";
      errorExits = true;
    }
    if (maxDiscount < 0 && discountType === "discount") {
      promocodeError['maxDiscount'] = "Enter a Valid Maximum discount (Greater than 0)";
      errorExits = true;
    }
    if (!newCustomer && (count < 0 || (count === undefined || count === null || count === "")) && category === "offline" && discountType != "discount") {
      promocodeError['count'] = "Enter Valid No of usages per Customer";
      errorExits = true;
    }
    setError(promocodeError);
    if (errorExits) {
      return
    }
    const AddData = {
      category: category,
      start: startDate,
      end: endDate,
      code: promoCode,
      type: discountType,
      status: status,
    }
    if (discountType === "discount") {
      AddData['percent'] = codePercentage;
      AddData['maximum'] = maxDiscount;
        AddData['minimumOrder'] = minOrder;
        AddData['perItemDiscount'] = perItemDiscount;
        AddData['autoApplyOnCreate'] = autoApplyOnCreate;
        if (perItemDiscount == true) {
          let filterData = rows.map((row) => {
            return {
              discountedPrice: row?.discountedPrice,
              item: row?.item,
              itemName: row?.item?.label,
              perItemType: row?.perItemType,
              maxItemsApplicable: row?.maxItemsApplicable
            };
          });
          AddData['item_discounted'] = filterData;
      }
    }
    if (discountType === "wallet") {
      AddData['amount'] = amount;
    }
    if (discountType === "areaFreeRechargeWallet") {
      AddData['amount'] = amount;
      AddData['minimumOrder'] = minOrder;
    }
    if (discountType === "areaDiscount" || discountType === "discount") {
      AddData['minimumOrder'] = minOrder;
      AddData['maximum'] = maxDiscount;
      AddData['percent'] = codePercentage;
      if (!perItemDiscount){
        AddData['services'] = serviceApplicable;
        AddData['excluded_items'] = promoCodeClothes;
      }


    }
    if (category === "online") {
      AddData['area'] = promoCodeArea;
      AddData['onlyNew'] = newCustomer;
      if (!newCustomer) {
        AddData['count'] = count;
      }
      if (isEdit) {
        AddData['laundry'] = [];
      }
    }
    if (category === "offline") {
      if (discountType === "discount") {
        AddData['laundry'] = promoCodeLaundry;
      }
      else if (discountType === "areaDiscount" || discountType === "areaFreeRechargeWallet") {
        AddData['onlyNew'] = newCustomer;
        AddData['laundry'] = laundryName.value
        AddData['count'] = count;
        // if (!newCustomer) {
        //   AddData['count'] = count;
        // }
      }
      else {
        AddData['laundry'] = laundryName.value
      }
      if (isEdit) {
        AddData['area'] = [];
      }
    }
    AddData['enableAll'] = enabledForAll;
    if (isEdit) {
      AddData['_id'] = selectedItem._id;
      AddData['checkstatus1'] = setcheckstatus
      AddData['checkstatus2'] = checkstatus
      AddData['extended'] = extended;
      dispatch(updatePromoCode(AddData));
      dispatch(getPromosCode({ webPermission: true, pageNo: 1, pageSize })); 
    } else {
      dispatch(createPromoCode(AddData))
      setlaundryName({ value: "", label: "" })
    }
  }

  const setExtendedStatus = (checked) => {
    let _extended = {};
    if (extended) {
      _extended = { ...extended };
    }
    _extended.status = checked;
    setExtended(_extended);
  }

  const setExtendedDate = (date) => {
    let _extended = {};
    if (extended) {
      _extended = { ...extended };
    }
    if (date && date.length > 0) {
      let extendedDate = moment(date[0]).format("YYYY-MM-DD");
      _extended.extendedDate = extendedDate;
      setExtended(_extended);
    }
  }

  useEffect(() => {
    if (!promocodeError?.promocode && addModal) {
      setAddModal(false)
    }
  }, [promocodeError])

  useEffect(() => {
    if (!addModal) {
      setCategory("online");
      setPromoCodeArea([]);
      setPromoCodeLaundry([]);
      setCodePercentage(0);
      setAmount(0);
      setDiscountType("discount");
      setPromoCode("");
      setStartDate("");
      setEndDate("");
      setStatus(true);
      setNewCustomer(true);
      setEnabledForAll(false);
      setCount(0);
      setMaxDiscount();
      setMinOrder();
      setError({});
      setSelectedItem({});
      setIsEdit(false)
      setUsageList([])
      setTransactionList([])
      setApiProcessing(false);
      setShowUsage(false)
      setserviceApplicable(serviceApplicableDefault)
      setExtended(null);
      setPromoCodeClothes([])
      setRows([{ item: '', discountedPrice: 0, perItemType:"", maxItemsApplicable: 0 }])
      setperItemDiscount(false)
    }
  }, [addModal])

  const handleStartDate = (selectedDate) => {
    let startDate = moment(selectedDate[0]).format("YYYY-MM-DD");
    setStartDate(startDate);
  }

  const handleEndDate = (selectedDate) => {
    let endDate = moment(selectedDate[0]).format("YYYY-MM-DD");
    setEndDate(endDate);
  }

  if (!userData?.permissions?.allPermissions && !userData?.permissions?.promoCode?.read) {
    return <Pages404 />
  }

  const handleShowUsage = () => {
    setApiProcessing(true);
    let apiData = { promocode: selectedItem._id }
    dispatch(getRecord(apiData))
  }
  const changeCategory = (val) => {
    if (val != undefined) {
      // setSelectedLaundry(null)
      setselectCategory(val)
    }
    else {
      // setSelectedLaundry(null)
      setselectCategory("")
      setSelectedArea("")
    }
  }
  const handleShowTransaction = () => {
    setApiProcessing(true);
    let apiData = { promocode: selectedItem._id, type: "promocode" }
    dispatch(getTransactions(apiData))
  }

  const discountUsageListColumn = [
    {
      id: "srNo",
      name: "Sr. No.",
      sortable: false,
      wrap: true,
      width: "10%",
      selector: (row, index) => usageList.findIndex((item) => item._id === row._id) + 1
    },
    {
      id: "OrderID",
      name: "Order ID",
      sortable: false,
      wrap: true,
      width: "12%",
      selector: (row, index) => row?.orderID
    },
    {
      id: "DiscountAmount",
      name: "Amount",
      sortable: false,
      wrap: true,
      width: "12%",
      selector: (row, index) => row?.discount
    },
    {
      id: "GrandTotal",
      name: "Grand Total",
      sortable: false,
      wrap: true,
      selector: (row, index) => row?.subTotal
    },
    {
      id: "InvoiceTime",
      name: "Invoice Time",
      sortable: false,
      wrap: true,
      selector: (row, index) => row?.invoiceTime
    },
    {
      id: "CustomerName",
      name: "Customer Name",
      style: { 'whiteSpace': 'break-spaces' },
      sortable: false,
      wrap: true,
      selector: (row, index) => row?.customer?.name
    },
    {
      id: "CustomerMobile",
      name: "Customer Mobile",
      sortable: false,
      wrap: true,
      selector: (row, index) => row?.customer?.mobile
    },
  ]

  const discountTransactionListColumn = [
    {
      id: "srNo",
      name: "Sr. No.",
      sortable: false,
      wrap: true,
      width: "12%",
      selector: (row, index) => transactionList.findIndex((item) => item._id === row._id) + 1
    },
    {
      id: "CustomerMobile",
      name: "Customer Mobile",
      sortable: false,
      wrap: true,
      selector: (row, index) => row?.customer?.mobile
    },
    {
      id: "CustomerName",
      name: "Customer Name",
      sortable: false,
      wrap: true,
      selector: (row, index) => row?.customer?.name
    },
    {
      id: "TransactionDate",
      name: "Transaction Date",
      sortable: false,
      wrap: true,
      width: "25%",
      selector: (row, index) => moment(row?.dateTime).format("YYYY-MM-DD h:mm:ss a")
    },
    {
      id: "Amount",
      name: "Amount",
      sortable: false,
      wrap: true,
      selector: (row, index) => row?.amount
    },
  ]

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Promo Code</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Promo Code" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="6">
                      <div className="me-2 mb-2 d-inline-block">
                        <div className="position-relative d-flex">
                          <input
                            id="search-bar-10"
                            type="text"
                            aria-labelledby="search-bar-10-label"
                            className="form-control"
                            style={{ width: "15rem", height: "38px" }}
                            placeholder="Search"
                            onChange={handleSearchChange} // Use handleChange to debounce setSearchValue
                          />
                          <div className="ms-2" style={{ width: "10rem" }}>
                            <Select
                              placeholder="Category"
                              onChange={val => {
                                changeCategory(val)
                              }}
                              isClearable={true}
                              options={[
                                { "label": "online", value: "online" },
                                { "label": "offline", value: "offline" },
                              ]}
                            />
                          </div>
                          {
                            selectCategory.value !== "offline" && <div className="ms-2" style={{ width: "12rem" }}>
                              <Select
                                placeholder="All Areas"
                                onChange={val => {
                                  setSelectedArea(val)
                                  setSelectedLaundry(null)
                                }}
                                value={selectedArea}
                                isClearable={true}
                                options={areas}
                              />
                            </div>
                          }
                          {
                            selectCategory.value === "offline" && <div className="ms-2" style={{ width: "12rem" }}>
                              <Select
                                placeholder="All Laundry"
                                onChange={val => {
                                  setSelectedLaundry(val)
                                }}
                                isClearable={true}
                                options={
                                  laundryList?.map((item) => {
                                    const label = item.shortName
                                      ? item.shortName
                                      : item.nameEn
                                    return { label, value: item._id }
                                  })
                                }
                              />
                            </div>
                          }
                          <div className="ms-2" style={{ width: "12rem" }}>
                            <Select
                              placeholder="All Types"
                              onChange={val => {
                                setSelectedType(val)
                              }}
                              isClearable={true}
                              options={typesList}
                            />
                          </div>

                          <div className="ms-2" style={{ width: "15rem" }}>
                            {
                              (userData?.permissions?.allPermissions == true || userData?.permissions?.promoCode?.create == true) ?
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded  mb-2 me-2"
                                  onClick={handleAddPromoCode}
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  Add Promo Code
                                </Button>
                                : null
                            }
                          </div>
                        </div>

                      </div>

                    </Col>


                  </Row>
                  <Row>
                    <Col sm="12" className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center mx-3">
                          <span className={`me-2 ${fetchStatusTrue ? "text-success font-weight-semibold" : ""}`}>Show Enabled</span>
                          <div className="form-check form-switch form-switch-lg">
                            <input
                              type="checkbox"
                              className="form-check-input form-slider-color-green"
                              defaultChecked={fetchStatusTrue}
                              onChange={(e) => { setFetchStatusTrue(!fetchStatusTrue) }}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className={`me-2 ${fetchStatusFalse ? "text-success font-weight-semibold" : ""}`}>Show Disabled</span>
                          <div className="form-check form-switch form-switch-lg">
                            <input
                              type="checkbox"
                              className="form-check-input form-slider-color-green"
                              defaultChecked={fetchStatusTrue}
                              onChange={(e) => { setFetchStatusFalse(!fetchStatusFalse) }}
                            />
                          </div>
                        </div>

                      </div>
                    </Col>
                  </Row>
                  <DataTable
                    columns={promoCodeListColumn}
                    data={fetchStatusFalse === false && fetchStatusTrue === false ? [] : promoCodeList}
                    pagination
                    progressPending={pending}
                    progressComponent={<Skeleton height={50} className="promoCode_skeleton" containerClassName="promoCode_skeleton" count={5} />}
                    paginationPerPage={pageSize}
                    paginationTotalRows={totalCount || 0}
                    paginationServer
                    paginationDefaultPage={currentPage}
                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => setPageSize(currentRowsPerPage)}
                    onChangePage={(page) => handlePageChange(page)}
                  />

                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={addModal} toggle={() => setAddModal(!addModal)} size="lg">
            <ModalHeader toggle={() => setAddModal(!addModal)} tag="h4">
              {isEdit ? "Edit" : "Add"} Promo Code
            </ModalHeader>
            <ModalBody style={{ height: 500, overflowY: "scroll" }}>
              <AvForm onValidSubmit={addPromoCode}>
                <Row>
                  <div className="mb-3 col-md-6">
                    <AvField
                      name="category"
                      label={<div>Category <span className="mandatory_feild">*</span></div>}
                      type="select"
                      disabled={isEdit}
                      onChange={(e) => { setCategory(e.target.value); setDiscountType("discount"); }}
                      value={category}
                    >
                      <option value={"online"}>Online</option>
                      <option value={"offline"}>Offline</option>
                    </AvField>
                  </div>
                  <div className="mb-3 col-md-6">
                    <AvField
                      name="typeOfDiscount"
                      label={<div>Type of Discount <span className="mandatory_feild">*</span></div>}
                      type="select"
                      disabled={isEdit}
                      onChange={(e) => setDiscountType(e.target.value)}
                      value={discountType}
                    >

                      {
                        category === "offline" ?
                          <>
                            <option value={"discount"}> Discount</option>
                            <option value={"areaDiscount"}> Area Discount</option>
                            <option value={"areaFreeRechargeWallet"}>Area Free Recharge Wallet</option>
                          </> : null
                      }
                      {
                        category === "online" ? (
                          <>
                            <option value={"discount"}>Discount</option>
                            <option value={"wallet"}>Wallet</option>
                          </>
                        ) : null
                      }
                    </AvField>
                  </div>
                  {
                    category === "online" ? (
                      <div className="mb-3 col-md-6">
                        <div className="d-flex align-items-start onlynew1">
                          <label>Areas <span className="mandatory_feild">*</span></label>
                          <div className="col-sm-7 form-check form-switch custom-toggle-sm">
                            <span className="mt-2 me-3">Select All</span>
                            <Switch
                              uncheckedIcon={<Nosymbol />}
                              checkedIcon={<YesSymbol />}
                              className="me-1 mb-sm-8 mb-2"
                              onColor="#626ed4"
                              onChange={() => setEnabledForAll(!enabledForAll)}
                              checked={enabledForAll}
                            />
                          </div>
                        </div>
                        <MultiSelect
                          defaultValue={promoCodeArea.toString()}
                          className="multi-selects form-selects2 form-controlss areas-box"
                          onChange={(e) => { delete error.area; handleAreaChange(e); }}
                          options={
                            areaList?.map((item) => {
                              return { label: item.name, value: item._id }
                            })
                          }
                          disabled={enabledForAll}
                        />
                        {
                          error?.area ? (
                            <label style={{ color: "red" }}>{error?.area}</label>
                          ) : null
                        }
                      </div>
                    ) : null
                  }
                  {
                    category === "offline" ? (
                      <div className="mb-3 col-md-6">
                        <div className="d-flex align-items-start onlynew1">
                          <label>Laundry <span className="mandatory_feild">*</span></label>

                          {
                            discountType === "discount" && <div className="col-sm-7 form-check form-switch custom-toggle-sm">
                              <span className="mt-2 me-3">Select All</span>
                              <Switch
                                uncheckedIcon={<Nosymbol />}
                                checkedIcon={<YesSymbol />}
                                className="me-1 mb-sm-8 mb-2"
                                onColor="#626ed4"
                                onChange={() => setEnabledForAll(!enabledForAll)}
                                checked={enabledForAll}
                              />
                            </div>
                          }

                        </div>

                        {
                          discountType === "discount" && <MultiSelect
                            defaultValue={promoCodeLaundry.toString()}
                            className="multi-selects form-selects2 form-controlss areas-box"
                            onChange={(e) => { delete error.laundry; handleLaundry(e); }}
                            options={
                              laundryList?.map((item) => {
                                const label = item.shortName
                                  ? item.shortName
                                  : item.nameEn
                                return { label, value: item._id }
                              })
                            }
                            disabled={enabledForAll}
                          />
                        }

                        {
                          (discountType === "areaFreeRechargeWallet" || discountType === "areaDiscount") && <Select
                            placeholder="Select"
                            value={laundryName}
                            isDisabled={isEdit}
                            className="multi-selects form-selects2 form-controls areas-box"
                            onChange={(e) => { handleLaundry(e); }}
                            options={
                              laundryList?.map((item) => {
                                const label = item.shortName
                                  ? item.shortName
                                  : item.nameEn
                                return { label, value: item._id }
                              })
                            }
                          />
                        }


                        {
                          error?.laundry ? (
                            <label style={{ color: "red" }}>{error?.laundry}</label>
                          ) : null
                        }
                      </div>
                    ) : null
                  }

                  <div className="mb-3 col-md-6">
                    <Label>Code <span className="mandatory_feild">*</span></Label>
                    <Input
                      name="code"
                      disabled={isEdit}
                      type="text"
                      placeholder="Enter Code"
                      onChange={(e) => { delete error.code; delete promocodeError.promocode; setPromoCode(e.target.value) }}
                      value={promoCode}
                    />
                    {
                      promocodeError?.promocode != "Processing" ? (
                        <label style={{ color: "red" }}>{promocodeError?.promocode}</label>
                      ) : null
                    }
                    {
                      error?.code ? (
                        <label style={{ color: "red" }}>{error?.code}</label>
                      ) : null
                    }
                  </div>
                  {
                    (discountType === "discount") && <div className="d-flex onlynew1">
                      <label htmlFor="horizontal-status-Input1" className="status-label">Per Item Discount</label>
                      <div className="col-sm-7 form-check form-switch custom-toggle-sm">
                        <Switch
                          uncheckedIcon={<Nosymbol />}
                          checkedIcon={<YesSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                          onChange={(e) => setperItemDiscount(!perItemDiscount)}
                          checked={perItemDiscount}
                        />
                      </div>
                    </div>
                  }
                  {(discountType === "discount") && perItemDiscount == true &&
                    <div className="mb-3 col-md-12 border">
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded mb-2 me-2"
                            onClick={addRow}
                          >
                            Add Row
                          </Button>
                          <table>
                            {rows?.length > 0 && (
                              <thead>
                                <tr>
                                  <th>Item</th>
                                  <th>Discount Value</th>
                                  <th>Type</th>
                                  <th>Max Item</th>
                                </tr>
                              </thead>
                            )}
                            <tbody>
                              {rows?.map((row, index) => (
                                <tr key={index}>
                                  <td style={{ width: "200px" }}>
                                    <Select
                                      placeholder="Select"
                                      value={
                                        row?.item
                                          ? {
                                              value: row?.item,
                                              label: clothesDataList?.find((item) => item._id === row.item)?.nameEn,
                                            }
                                          : null
                                      }
                                      className="multi-selects form-selects2 form-controls areas-box"
                                      onChange={(e) => handleChange(index, 'item', e)}
                                      options={clothesDataList?.map((item) => ({
                                        label: item.nameEn,
                                        value: item._id,
                                      }))}
                                    />
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <Input
                                        className="no-spinner me-1"
                                        type="number"
                                        value={row.discountedPrice}
                                        onChange={(e) => {
                                          const value = e.target.value;

                                          if (isNaN(value)) return;

                                          const numValue = parseFloat(value);
                                          if (numValue < 0) return;

                                          handleChange(index, 'discountedPrice', numValue);
                                        }}
                                        required
                                      />
                                    </div>
                                  </td>
                                  <td style={{ width: "70px" }}>
                                    <Select
                                      placeholder="Type"
                                      value={
                                        row?.perItemType
                                          ? {
                                              value: row?.perItemType,
                                              label: perItemType.find(item => item?.value?.toLowerCase() === row?.perItemType?.toLowerCase())?.label || "",
                                            }
                                          : { value: "", label: "" }
                                      }
                                      className="multi-selects form-selects2 form-controls areas-box p-0"
                                      onChange={(e) => handleChange(index, 'perItemType', e)}
                                      options={perItemType?.map((item) => ({
                                        label: item.label,
                                        value: item.value,
                                      }))}
                                    />
                                  </td>
                                  <td>
                                    <Input
                                      className="no-spinner"
                                      type="number"
                                      value={row.maxItemsApplicable}
                                      onChange={(e) => {
                                        const value = e.target.value;

                                        if (isNaN(value)) return;

                                        const numValue = parseInt(value);
                                        if (numValue < 0) return;

                                        handleChange(index, 'maxItemsApplicable', numValue);
                                      }}
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      type="button"
                                      color="danger"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={() => deleteRow(index)}
                                    >
                                      Delete
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            {error?.perItemDiscount && (
                              <tfoot>
                                <tr>
                                  <td colSpan="5">
                                    <label style={{ color: "red" }}>{error?.perItemDiscount}</label>
                                  </td>
                                </tr>
                              </tfoot>
                            )}
                          </table>
                        </div>
                  }
                  {
                    (discountType === "discount" && perItemDiscount == false) ? (
                      <div className="mb-3 col-md-6">
                        <Label>Percentage <span className="mandatory_feild">*</span></Label>
                        <Input
                          name="percentage"
                          type="number"
                          disabled={isEdit}
                          placeholder="Enter Percentage"
                          onChange={(e) => { delete error.percentage; setCodePercentage(e.target.value); }}
                          value={codePercentage}
                        />
                        {
                          error?.percentage ? (
                            <label style={{ color: "red" }}>{error?.percentage}</label>
                          ) : null
                        }
                      </div>
                    ) : null
                  }
                  {
                    discountType === "areaDiscount" ? (
                      <div className="mb-3 col-md-6">
                        <Label>Percentage <span className="mandatory_feild">*</span></Label>
                        <Input
                          name="percentage"
                          className="multi-selects form-selects2 form-controlss areas-box"
                          type="number"
                          disabled={isEdit}
                          placeholder="Enter Percentage"
                          onChange={(e) => { delete error.percentage; setCodePercentage(e.target.value); }}
                          value={codePercentage}
                        />
                        {
                          error?.percentage ? (
                            <label style={{ color: "red" }}>{error?.percentage}</label>
                          ) : null
                        }
                      </div>
                    ) : null
                  }
                  {
                    discountType === "wallet" ? (
                      <div className="mb-3 col-md-6">
                        <Label>Amount <span className="mandatory_feild">*</span></Label>
                        <Input
                          name="amount"
                          type="number"
                          placeholder="Enter Amount"
                          disabled={isEdit}
                          onChange={(e) => { delete error.amount; setAmount(e.target.value); }}
                          value={amount}
                        />
                        {
                          error?.amount ? (
                            <label style={{ color: "red" }}>{error?.amount}</label>
                          ) : null
                        }
                      </div>
                    ) : null
                  }
                  {
                    discountType === "areaFreeRechargeWallet" ? (
                      <div className="mb-3 col-md-6">
                        <Label>Amount <span className="mandatory_feild">*</span></Label>
                        <Input
                          name="amount"
                          type="number"
                          placeholder="Enter Amount"
                          disabled={isEdit}
                          onChange={(e) => { delete error.amount; setAmount(e.target.value); }}
                          value={amount}
                        />
                        {
                          error?.amount ? (
                            <label style={{ color: "red" }}>{error?.amount}</label>
                          ) : null
                        }
                      </div>
                    ) : null
                  }
                  {
                    (discountType === "discount") || (discountType === "areaDiscount") ? (
                      <div className="mb-3 col-md-6">
                        <Label>Maximum Discount <span className="mandatory_feild">*</span></Label>
                        <Input
                          name="maximumDiscount"
                          type="number"
                          className="no-spinner"
                          placeholder="Enter Maximum Discount"
                          disabled={discountType === "discount" && isEdit == true ? true : false}
                          onChange={(e) => { delete error.maxDiscount; setMaxDiscount(e.target.value); }}
                          value={maxDiscount}
                        />
                        {
                          error?.maxDiscount ? (
                            <label style={{ color: "red" }}>{error?.maxDiscount}</label>
                          ) : null
                        }
                        <div></div>
                        {
                          (discountType == "areaFreeRechargeWallet" || discountType == "areaDiscount") && <span className="text-danger mt-1">If you enter 0, it means no restrictions</span>
                        }
                      </div>
                    ) : null
                  }
                  {
                    (category === "online" && discountType === "wallet") || category === "offline" && (discountType === "areaDiscount" || discountType === "areaFreeRechargeWallet") ? (
                      <div className="col-sm-6">
                        {/*  */}
                        <div className="d-flex onlynew1">
                          <label htmlFor="horizontal-status-Input1" className="status-label">Only New Customer</label>
                          <div className="col-sm-7 form-check form-switch custom-toggle-sm">
                            <Switch
                              uncheckedIcon={<Nosymbol />}
                              checkedIcon={<YesSymbol />}
                              className="me-1 mb-sm-8 mb-2"
                              onColor="#626ed4"
                              onChange={(e) => setNewCustomer(!newCustomer)}
                              checked={newCustomer}
                            />
                          </div>
                        </div>
                        <Input
                          name="count"
                          disabled={newCustomer}
                          type="number"
                          className="no-spinner"
                          placeholder="No. of usages per customer"
                          onChange={(e) => { delete error.count; setCount(e.target.value); }}
                          value={count}
                        />
                        {
                          error?.count ? (
                            <label style={{ color: "red" }}>{error?.count}</label>
                          ) : null
                        }
                        <div></div>
                        {
                          (discountType == "areaFreeRechargeWallet" || discountType == "areaDiscount") && <span className="text-danger mt-1">If you enter 0, it means no restrictions</span>

                        }
                      </div>
                    ) : null
                  }
                  {
                    category === "offline" && (discountType === "discount" || discountType === "areaFreeRechargeWallet" || discountType === "areaDiscount") ? (
                      <div className="mb-3 col-md-6">
                        <Label>Minimum Order <span className="mandatory_feild">*</span></Label>
                        <Input
                          name="minimumOrder"
                          type="number"
                          placeholder="Enter Minimum Order"
                          disabled={discountType === "discount" && isEdit == true ? true : false}
                          onChange={(e) => { delete error.minOrder; setMinOrder(e.target.value); }}
                          value={minOrder}
                        />
                        {
                          error?.minOrder ? (
                            <label style={{ color: "red" }}>{error?.minOrder}</label>
                          ) : null
                        }
                        <div></div>
                        {
                          (discountType == "areaFreeRechargeWallet" || discountType == "areaDiscount") && <span className="text-danger mt-1">If you enter 0, it means no restrictions</span>
                        }
                      </div>
                    ) : null
                  }
                  <div className="mb-3 col-md-6">
                    <label>Start Date <span className="mandatory_feild">*</span></label>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="Select Start Date"
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                        "disable": [
                          function (date) {
                            // return true to disable
                            // if(discountType === "areaFreeRechargeWallet" ){
                            //   return true
                            // }
                            return false

                          }
                        ],
                      }}
                      onChange={(e) => { handleStartDate(e); }}
                      value={startDate}
                    />
                    {
                      error?.startDate ? (
                        <label style={{ color: "red" }}>{error?.startDate}</label>
                      ) : null
                    }
                    {/* {
                     ( discountType== "areaFreeRechargeWallet")  && <span className="text-danger mt-1">Start date not being used when type is {discountType}</span>
                    } */}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label>End Date <span className="mandatory_feild">*</span></label>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="Select End Date"
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                        "disable": [
                          function (date) {
                            // return true to disable
                            // if(discountType === "areaFreeRechargeWallet" ){
                            //   return true
                            // }
                            return false

                          }
                        ],
                      }}
                      onChange={(e) => { delete error.endDate; handleEndDate(e); }}
                      value={endDate}
                    />
                    {
                      error?.endDate ? (
                        <label style={{ color: "red" }}>{error?.endDate}</label>
                      ) : null
                    }
                    {/* {
                     (discountType === "areaFreeRechargeWallet" )  && <span className="text-danger mt-1">End date not being used when type is {discountType}</span>
                    } */}
                  </div>
                  {
                    category === "online" && discountType === "discount" ? (
                      <div className="col-sm-6">
                        <div className="d-flex onlynew1">
                          <label htmlFor="horizontal-status-Input1" className="status-label">Only New Customer </label>
                          <div className="col-sm-7 form-check form-switch custom-toggle-sm">
                            <Switch
                              uncheckedIcon={<Nosymbol />}
                              checkedIcon={<YesSymbol />}
                              className="me-1 mb-sm-8 mb-2"
                              onColor="#626ed4"
                              onChange={(e) => setNewCustomer(!newCustomer)}
                              checked={newCustomer}
                            />
                          </div>
                        </div>
                        <Input
                          name="count"
                          disabled={category !== "online" || newCustomer}
                          // label={<div>Count <span className="mandatory_feild">*</span></div>}
                          type="number"
                          placeholder="No. of usages per customer"
                          onChange={(e) => { delete error.count; setCount(e.target.value); }}
                          value={count}
                        />
                        {
                          error?.count ? (
                            <label style={{ color: "red" }}>{error?.count}</label>
                          ) : null
                        }
                      </div>
                    ) : null
                  }

                  {
                    ((discountType === "areaDiscount" || discountType === "discount") && !perItemDiscount) && <div className="mb-3 col-md-6">
                      <label
                        htmlFor="horizontal-status-Input1"
                        className="status-label"
                      >Services Applicable
                      </label>
                      <Form style={{ margin: "4px 0" }}>
                        <div>
                          <FormGroup
                            check
                            inline
                          >
                            <Input type="checkbox" checked={serviceApplicable?.iron} onChange={() => handleCheckboxChange("iron")} />
                            <Label check>
                              Iron
                            </Label>
                          </FormGroup>
                          <FormGroup
                            check
                            inline
                          >
                            <Input type="checkbox" checked={serviceApplicable?.dryClean} onChange={() => handleCheckboxChange("dryClean")} />
                            <Label check>
                              Dry Clean
                            </Label>
                          </FormGroup>
                          <FormGroup
                            check
                            inline
                          >
                            <Input type="checkbox" checked={serviceApplicable?.washIron} onChange={() => handleCheckboxChange("washIron")} />
                            <Label check>
                              Wash & Iron
                            </Label>
                          </FormGroup>
                          <FormGroup
                            check
                            inline
                          >
                            <Input type="checkbox" checked={serviceApplicable?.expressIron} onChange={() => handleCheckboxChange("expressIron")} />
                            <Label check>
                              Express Iron
                            </Label>
                          </FormGroup>
                        </div>
                        <div className="mt-2">
                          <FormGroup
                            check
                            inline
                          >
                            <Input type="checkbox" checked={serviceApplicable?.expressDryClean} onChange={() => handleCheckboxChange("expressDryClean")} />
                            <Label check>
                              Express Dry Clean
                            </Label>
                          </FormGroup>
                          <FormGroup
                            check
                            inline
                          >
                            <Input type="checkbox" checked={serviceApplicable?.expressWashIron} onChange={() => handleCheckboxChange("expressWashIron")} />
                            <Label check>
                              Express Wash & Iron
                            </Label>
                          </FormGroup>

                        </div>

                      </Form>
                      {
                        ((discountType == "areaDiscount" || discountType == "discount") && (serviceApplicable.dryClean == false && serviceApplicable.iron == false && serviceApplicable.washIron == false && serviceApplicable.expressIron == false && serviceApplicable.expressDryClean == false && serviceApplicable.expressWashIron == false)) && <span className="text-danger">If all services unchecked, it will disable the promocode</span>
                      }
                    </div>
                  }
                  {
                    ((discountType === "areaDiscount" || discountType === "discount") && !perItemDiscount) ? (
                      <div className="mb-3 col-md-6">
                        <div className="d-flex align-items-start onlynew1">
                          <label>Excluded Items</label>
                        </div>
                        <MultiSelect
                          defaultValue={promoCodeClothes?.toString()}
                          className="multi-selects form-selects2 form-controlss areas-box"
                          onChange={(e) => { handleClothChange(e); }}
                          options={
                            clothesDataList?.map((item) => {
                              return { label: item.nameEn, value: item._id }
                            })
                          }
                        // disabled={enabledForAll}
                        />
                        {
                          error?.area ? (
                            <label style={{ color: "red" }}>{error?.area}</label>
                          ) : null
                        }
                      </div>
                    ) : null
                  }
                  <div className="mb-3 col-md-6">
                    <label
                      htmlFor="horizontal-status-Input1"
                      className="status-label"
                    >Status
                    </label>
                    <div className="custom-toggle-lg mt-1">
                      <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        className="me-1 mb-2"
                        onColor="#626ed4"
                        onChange={(e) => setStatus(!status)}
                        checked={status}
                      />
                    </div>
                  </div>


                  {
                    discountType === "areaDiscount" && isEdit && <div className="mb-3 col-md-6">

                      <Form style={{ margin: "4px 0" }}>
                        <div>
                          <FormGroup
                            check
                            inline
                          >
                            <Input type="checkbox" checked={extended?.status} onChange={(e) => setExtendedStatus(e.target.checked)} />
                            <Label check>
                              Extend Area Discount
                            </Label>
                          </FormGroup>
                          {extended?.status &&
                            <div className="mt-2">
                              <label>Extended Date <span className="mandatory_feild">*</span></label>
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="Select Extended Date"
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                  "disable": [
                                    function (date) {
                                      // return true to disable
                                      // if(discountType === "areaFreeRechargeWallet" ){
                                      //   return true
                                      // }
                                      return false

                                    }
                                  ],
                                }}
                                onChange={(e) => { delete error.extendedDate; setExtendedDate(e); }}
                                value={extended?.extendedDate}
                              />
                            </div>}
                        </div>
                      </Form>
                    </div>

                  }
                  {
                    (discountType === "discount") && <div className="d-flex onlynew1">
                      <label htmlFor="horizontal-status-Input1" className="status-label">Auto Apply on Create Order</label>
                      <div className="col-sm-7 form-check form-switch custom-toggle-sm">
                        <Switch
                          uncheckedIcon={<Nosymbol />}
                          checkedIcon={<YesSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                          onChange={(e) => setautoApplyOnCreate(!autoApplyOnCreate)}
                          checked={autoApplyOnCreate}
                        />
                      </div>
                    </div>
                  }

                </Row>
                <Row>

                </Row>
                <div className="text-end">
                  {
                    isEdit ? (
                      apiProcessing ?
                        <Button
                          color="secondary"
                          className="btn-rounded "
                          type="button"
                          disabled
                        >
                          <i className="bx bx-loader bx-spin font-size-18 align-middle me-2"></i>
                          Processing...
                        </Button> :
                        <Button
                          // color="secondary"
                          className="btn-rounded btn-default"
                          type="button"
                          onClick={discountType == "discount" ? handleShowUsage : handleShowTransaction}
                        >Show Usage</Button>
                    ) : null
                  }
                  {
                    userData?.permissions?.allPermissions || userData?.permissions?.promoCode?.update || !isEdit ? (
                      <Button
                        type="submit"
                        color="success"
                        className="btn-rounded mx-2"
                      >Save</Button>
                    ) : null
                  }
                </div>
              </AvForm>
              <div className="mt-3">
                {
                  showUsage && discountType == "discount" ? (
                    <DataTable
                      columns={discountUsageListColumn}
                      data={usageList}
                      pagination
                    />
                  ) : null
                }
                {
                  showUsage && discountType == "wallet" ? (
                    <DataTable
                      columns={discountTransactionListColumn}
                      data={transactionList}
                      pagination
                    />
                  ) : null
                }
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default withRouter(PromoCode);
