import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Input, Button, CardText } from "reactstrap";
import MetaTags from 'react-meta-tags';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { searchCustomer } from "store/actions";
import toastr from "toastr";
import { postWeb } from "helpers/api_helper";
import Pages404 from "pages/Utility/pages-404";
import { myParseFloat } from "helpers/validationHelper";

const FreeMoneySupport = () => {
  const dispatch = useDispatch();
  const { users, searchedCustomer } = useSelector(state => ({
    users: state.Users.users,
    searchedCustomer: state.Customers.searchedCustomer
  }));
  const initailStateOfErrors = {
    notFound:'',
    customerID:'',
    newFreeMoney:'',
    approvedBy:'',
    // suggestedBy:'',
    reason:'',
  }
  const [customerData, setCustomerData] = useState({});
  const [customerID, setCustomerID] = useState("");
  const [newFreeMoney, setNewFreeMoney] = useState("");
  const [approvedBy, setApprovedBy] = useState("");
  // const [suggestedBy, setSuggestedBy] = useState("");
  const [reason, setReason] = useState("");
  const [userData, setUserData] = useState({});
  const [error, setError] = useState(initailStateOfErrors)
  const [updateClicked, setUpdateClicked] = useState(false)

  useEffect(() => {
    if (typeof searchedCustomer == 'object' && Object.keys(searchedCustomer).length) {
      setCustomerData(searchedCustomer);
    }
    else if(customerID && !Object.keys(searchedCustomer).length){
      setError({...error, notFound:'Customer details not found'})
    }
  }, [searchedCustomer]);

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])

  useEffect(()=>{
    setCustomerData({})
  },[])

  useEffect(() => {
    if (customerID) {
      setCustomerData({});
    }
  }, [customerID]);  
  
  const handleSearch = async () => {
    setCustomerData({})
    setError(initailStateOfErrors)
    setApprovedBy('')
    setNewFreeMoney('')
    setReason('')
    // setSuggestedBy('')
    if (customerID) {
      dispatch(searchCustomer({customerID}));
    } else {
      setError({...error, customerID:'Please enter a valid customer ID'})
    }
  };
  const handleFreeMoneyChange = (e) => {
    let value = e.target.value;

    // Allowing user to clear the input field
    if (value === '') {
      setError(initailStateOfErrors);
      setNewFreeMoney('');
      return;
    }
    
    // Check if the value is a number and greater than 0 else return
    if(isNaN(value) || value <= 0){
      return;
    }

    setError(initailStateOfErrors)
    setNewFreeMoney(value);
  };

  const handleChange = (e) => {
    setError(initailStateOfErrors)
    let value = e.target.value;
    // Remove any non-digit characters
    value = value.replace(/[^\d]/g, '');
    if (value === '0') {
      value = ''; // or set it to some default value if needed
    }
    setCustomerID(value);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };
  useEffect(()=>{
    console.log('error',error)
  },[error])
  const handleUpdate = async (e) => {
    if(!customerID || customerID == ''){
      setError({...error,customerID:"Please enter Customer ID."});
      return;
    } 
    if (!newFreeMoney) {
      setError({...error,newFreeMoney:"Please enter the free money amount to be added."});
      return }
    if (!approvedBy) {
      setError({...error,approvedBy:"Please enter the approver's name."});
      return;
    } 
    // if (!suggestedBy) {
    //   setError({...error,suggestedBy:"Please enter the suggester's name."});
    //   return;
    // }
     else if(!reason){
      setError({...error,reason:"Please enter reason."});
      return;
     }
    e.target.disabled = true; 
    setUpdateClicked(true);
    if (newFreeMoney && reason) {
      try {
        const response = await postWeb("customers/addFreeMoney", { customerID, amountToAdd: newFreeMoney, approvedBy, reason });
        
        if (response.statusCode == 200) {
        toastr.success("Free money amount updated successfully.");
        setNewFreeMoney("");
        setApprovedBy("");
        //setSuggestedBy("");
        setReason("");
        setCustomerID("");
        setUpdateClicked(false);
        } else if (response.statusCode == 400) {
          //For free money amount limit.
          if (response?.msg && response.msg.includes("Amount should be less than")) {
            setError({ ...error, newFreeMoney: response?.msg });
          } else {
            toastr.error(response?.msg ?? "An error occurred while updating the free money amount.");
          }
          setUpdateClicked(false);
        }
      } catch (error) {
        toastr.error("An error occurred while updating the free money amount.");
        setUpdateClicked(false);
      }
    } else {
      toastr.error("Please fill in all fields.");
    }
  };
  if(userData?.permissions?.allPermissions==true || userData?.permissions?.supportFreeMoney?.update == true){
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Kleen | Free Money Support</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Support Tools" breadcrumbItem="Free Money Support" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                    <Col sm="12">
                        <div className="me-2 mb-2 d-inline-block">
                          <div className="position-relative d-flex gap-1 justify-content-evenly align-items-center">
                            <CardText style={{
                              fontFamily:`Poppins", sans-serif`
                            }} className="mb-2 pt-2 fs-5 ">Customer ID: </CardText>
                            <div className="ms-2 position-relative" style={{ width: "10rem" }}>
                              {/* Input to search customer by Customer ID */}
                              <div className="input-group">
                                <Input
                                  type="text"
                                  placeholder="Search Customer"
                                  className="form-control"
                                  value={customerID}
                                  invalid={error?.customerID?.length > 0}
                                  disabled={updateClicked}
                                  onChange={handleChange}
                                  onKeyPress={handleKeyPress} // Call handleSearch on Enter key press
                                />
                              </div>
                              
                              {error?.customerID?.length > 0 && <span className="text-danger position-absolute">{error?.customerID}</span>}
                            </div>

                            <Button
                              className="ms-2"
                              color="primary"
                              onClick={handleSearch}
                            >
                              Search
                            </Button>
                          </div>
                          
                        </div>
                      </Col>
                    </Row>
                    
                    <Row>
                   {(typeof customerData === 'object' && Object.keys(customerData).length > 0) && customerID ?  <Col sm="12">
                      <Row>
                        <Col sm="4" className="d-flex gap-1">
                            <CardText style={{
                                fontFamily:`Poppins", sans-serif`
                              }} className="mb-2 pt-2 fs-5 fw-bold">Name:</CardText>
                            <CardText  style={{
                                fontFamily:`Poppins", sans-serif`
                              }} className="mb-2 pt-2 fs-5  ">{customerData.name || "N/A"}</CardText>
                        </Col>
                        <Col sm="4" className="d-flex gap-1">
                            <CardText style={{
                                fontFamily:`Poppins", sans-serif`
                              }} className="mb-2 pt-2 fs-5 fw-bold">Mobile:</CardText>
                            <CardText  style={{
                                fontFamily:`Poppins", sans-serif`
                              }} className="mb-2 pt-2 fs-5  ">{customerData.mobile || "N/A"}</CardText>
                        </Col>
                      </Row>
                        <Row>
                          <Col sm="4" className="d-flex gap-1">
                            <CardText style={{
                              fontFamily:`Poppins", sans-serif`
                            }} className="mb-2 pt-2 fs-5 fw-bold ">Existing Wallet Amount:</CardText>
                            <CardText  style={{
                              fontFamily:`Poppins", sans-serif`
                            }} className="mb-2 pt-2 fs-5  ">{customerData.wallet == 0 ? 0 : customerData.wallet || "N/A"}</CardText>
                          </Col>
                          <Col sm="4" className="d-flex gap-1">
                            <CardText style={{
                              fontFamily:`Poppins", sans-serif`
                            }} className="mb-2 pt-2 fs-5 fw-bold">Existing Gift Balance:</CardText>
                            <CardText   className="mb-2 pt-2 fs-5 ">{customerData && (myParseFloat(customerData?.gifts?.promocode) + myParseFloat(customerData?.gifts?.joiningBonus) + myParseFloat(customerData?.gifts?.areaFreeRechargeWallet))}</CardText>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col sm="4">
                            <CardText style={{
                              fontFamily:`Poppins", sans-serif`
                            }} className="mb-2 pt-2 fs-5 fw-bold">Free Money Amount to be Added:</CardText>
                            <Input
                              type="text"
                              placeholder="Enter free money amount"
                              className="form-control"
                              value={newFreeMoney}
                              onChange={handleFreeMoneyChange}
                              invalid={error?.newFreeMoney?.length}
                              style={{
                                fontFamily:`Poppins", sans-serif`
                              }}
                            />
                              {error?.newFreeMoney?.length > 0 && <span className="mt-4 text-danger ">{error?.newFreeMoney}</span>}
                          </Col>
                          <Col sm="4">
                            <CardText  style={{
                                fontFamily:`Poppins", sans-serif`
                              }} className="mb-2 pt-2 fs-5 fw-bold">Approved By:</CardText>
                            <Input
                              type="text"
                              placeholder="Enter approver's name"
                              className="form-control"
                              value={approvedBy}
                              onChange={(e) => {
                                setError(initailStateOfErrors)
                                setApprovedBy(e.target.value)
                              }}
                              invalid={error?.approvedBy?.length}
                            />
                             {error?.approvedBy?.length > 0 && <span className="mt-4 text-danger ">{error?.approvedBy}</span>}
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          {/* <Col sm="4">
                            <CardText className="mb-2 pt-2 fs-5 fw-bold">Suggested By:</CardText>
                            <Input
                              type="text"
                              placeholder="Enter suggester's name"
                              className="form-control"
                              value={suggestedBy}
                              onChange={(e) => setSuggestedBy(e.target.value)}
                              invalid={error?.suggestedBy?.length}
                            />
                              {error?.suggestedBy?.length > 0 && <span className="mt-4 text-danger ">{error?.suggestedBy}</span>}
                          </Col> */}
                          <Col sm="4">
                            <CardText  style={{
                                fontFamily:`Poppins", sans-serif`
                              }} className="mb-2 pt-2 fs-5 fw-bold">Reason for Change:</CardText>
                            <Input
                              type="textarea"
                              placeholder="Enter reason for change"
                              style={{resize: "none"}}
                              className="form-control"
                              value={reason}
                              onChange={(e) => {
                                setError(initailStateOfErrors)
                                setReason(e.target.value)
                              }}
                              invalid={error?.reason?.length}
                            />
                              {error?.reason?.length > 0 && <span className="mt-4 text-danger ">{error?.reason}</span>}
                          </Col>
                        </Row>
                        <Row className="mt-3">
                        </Row>
                        <Button
                          className="mt-3"
                          color="success"
                          onClick={handleUpdate}
                        >
                          Update Free Money Amount
                        </Button>
                      </Col>: (customerID && error?.notFound?.length > 0) ? (
                          <p className="text-danger mb-2 pt-2 fs-5 fw-bold">{error?.notFound}</p>
                      ) : null}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
  else{
    return <Pages404/> 
  }
};

export default FreeMoneySupport;
